<template>
  <div>
    <b-row class="justify-content-end">
      <b-col
        v-if="showButton"
        md="auto"
      >
        <b-button
          variant="primary"
        >
          Nouvel Utilisateur
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <label>Societé</label>
          <v-select
            v-model="filter.societe"
            label="societe"
            placeholder="Sélectionnez une société"
            :options="societes"
          >
            <div slot="no-options">
              Option inexistante
            </div>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <label>Agence</label>
          <v-select
            v-model="filter.agence"
            label="agence"
            placeholder="Selectionné une agence"
            :options="agences"
          >
            <div slot="no-options">
              Option inexistante
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>Responsable </label>
          <b-form-input
            v-model="filter.responsable"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>Email </label>
          <b-form-input
            v-model="filter.email"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-end">
      <b-col
        md="auto"
      >
        <b-button
          variant="primary"
          type="button"
          @click="onSearch"
        >
          Rechercher
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterUserForm',
  components: {
    vSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  props: {
    agences: {
      type: Array,
      default() {
        return []
      },
    },
    societes: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      filter: {
        agence: '',
        societe: '',
        responsable: '',
        email: '',
      },
      showButton: false,
    }
  },
  beforeMount() {
  },
  methods: {
    onSearch() {
      this.$emit('filter', this.filter)
    },
  },
}
</script>

<style>

</style>
