<template>
  <div>
    <UpdateCreditModale
      :info-modale="infoModale"
      :message-success="messageSuccessCreditUpdate"
    />
    <UpdateTarifCredit
      :info-modale-price="infoModalePrice"
      :message-success="messageSuccessTarifCreditUpdate"
    />
    <SuccessUpdateModale />
    <SuccessUpdateTarifCreditModale />

    <b-card>
      <FilterUserForm
        :societes="societes"
        :agences="agences"
        @filter="resetFilter"
      />

    </b-card>
    <b-card v-if="users.length">
      <b-table
        ref="refUserTable"
        :items="users"
        responsive
        :fields="fields"
        primary-key="id"
        :fixed="true"
      >
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | luxon({ output: "dd/MM/yyyy" }) }}
        </template>

        <template #cell(identity)="data">
          {{ data.item.prenom }} {{ data.item.nom }}
        </template>

        <template #cell(solde)="data">
          {{ data.item.solde }}
          <feather-icon
            icon="EditIcon"
            size="16"
            class="mx-1"
            variant="primary"
            color="#609df7"
            @click="showModale(data.item)"
          />
        </template>

        <template #cell(tarif_credit)="data">
          {{ data.item.tarif_credit }}
          <feather-icon
            icon="EditIcon"
            size="16"
            class="mx-1"
            variant="primary"
            color="#609df7"
            @click="showModaleAddPrice(data.item)"
          />
        </template>

        <template #cell(role)="data">
          <b-badge
            pill
            :variant="getVariant(data.item.role)"
          >
            {{ getBadgeLabel(data.item) }}
          </b-badge>

          <b-form-checkbox
            :key="data.item.id"
            v-model="data.item.isAdmin"
            class="mt-1"
            switch
            size="lg"
            @change="setAdmin($event,data.item.id, data.item.role)"
          />
        </template>

      </b-table>
      <b-row>
        <b-col
          cols="6"
          class="text-left"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Afficher</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-25"
            />
            <span class="ml-2">sur {{ totalRows }} lignes</span>
          </b-form-group>

        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>

      </b-row>

    </b-card>
    <b-card
      v-else
      :title="notFound"
      class="text-center"
    />

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BCard,
  BFormCheckbox,
  BTable,
  BBadge,
  BPagination,
} from 'bootstrap-vue'
import FilterUserForm from './filterUserForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BCard,
    FilterUserForm,
    BFormCheckbox,
    BTable,
    BBadge,
    BPagination,
  },
  data() {
    return {
      infoModale: {
        show: false,
        id_user: null,
        solde: null,
      },
      infoModalePrice: {
        show: false,
        id_user: null,
        tarif_credit: null,
      },
      selected: '',
      checboxes: [],
      fields: [
        { key: 'identity', label: 'nom', thClass: 'lol' },
        { key: 'email', label: 'email' },
        { key: 'societe', label: 'Societé' },
        { key: 'agence', label: 'Agence' },
        { key: 'responsable', label: 'Responsable' },
        { key: 'email', label: 'Email' },
        { key: 'solde', label: 'Crédits(s)' },
        { key: 'tarif_credit', label: 'prix unitaire' },
        { key: 'role', label: 'role' },
      ],
      perPage: 10,
      pageOptions: [10, 25, 50],
      currentPage: 1,
      filter: {
        agence: '',
        societe: '',
        responsable: '',
        email: '',
      },
    }
  },
  computed: {
    users() {
      return this.$store.getters['user/getAllUsers']
    },
    totalRows() {
      return this.$store.getters['user/getTotal']
    },
    societes() {
      return this.$store.getters['user/getSocietes']
    },
    agences() {
      return this.$store.getters['user/getAgences']
    },
    notFound() {
      return this.$store.getters['user/getNotFoundInfo']
    },
    messageSuccessCreditUpdate() {
      return this.$store.getters['user/getMessageUpdatecredit']
    },
    messageSuccessTarifCreditUpdate() {
      return this.$store.getters['user/getMessageUpdateTarifCredit']
    },
  },
  watch: {
    perPage() {
      this.currentPage = 1
      this.filteruser()
    },
    currentPage() {
      this.filteruser()
    },
  },

  beforeMount() {
    this.$store.dispatch('user/getAllUsers', { limit: this.perPage, offset: (this.currentPage - 1) * this.perPage })
    this.$store.dispatch('user/getAllSocietes')
    this.$store.dispatch('user/getAllagences')
  },
  methods: {
    resetFilter(filter) {
      this.currentPage = 1
      this.filter = filter
      this.filteruser()
    },
    filteruser() {
      const params = this.filter
      params.limit = this.perPage
      params.offset = (this.currentPage - 1) * this.perPage
      this.$store.dispatch('user/getAllUsers', params)
    },
    showModale(data) {
      this.infoModale.show = true
      this.infoModale.id_user = data.id
      this.infoModale.solde = data.solde
    },
    showModaleAddPrice(data) {
      this.infoModalePrice.show = true
      this.infoModalePrice.id_user = data.id
      this.infoModalePrice.tarif_credit = data.tarif_credit
    },

    setAdmin(e, idUser, role) {
      let action = ''
      action = role === 'SQE_services' ? 'downgrade' : 'upgrade'
      this.$store.dispatch('user/updateUserToAdmin', { id: idUser, role, action })
    },
    getBadgeLabel(data) {
      return data.isAdmin ? 'Admin' : 'Client'
    },
    getVariant(data) {
      let variant = ''
      if (data === 'SQE_services') {
        variant = 'light-success'
      } else {
        variant = 'light-danger'
      }

      return variant
    },

  },
}
</script>

<style scoped>

</style>
